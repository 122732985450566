import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import Footer from "./components/structure/Footer";
import Studio from "./routes/Studio";
import StudioRoom from "./routes/StudioRoom";
import Equip from "./routes/Equip";
import Reservation from "./routes/Reservation";
import Address from "./routes/Address";
import ScrollToTop from "./components/ScrollToTop";
import Schedule from "./routes/Schedule";
import WriteReservation from "./components/board/WriteReservation";
import ReservationView from "./components/board/ReservationView";
import GalleryPart from './routes/GalleryPart';
import {CheckoutPage} from './payment/Checkout.jsx';
import {SuccessPage} from './payment/Success.jsx';
import {FailPage} from './payment/Fail.jsx';
import { authService } from "./firebase";

import { useEffect, useState } from "react";
import Auth from "./components/board/Auth";
import Search from "./components/board/Search";

function App() {  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userObj, setUserObj] = useState(null);

  useEffect(() => {
    authService.onAuthStateChanged((user)=>{      
      if(user) {
        setIsLoggedIn(true);
        setUserObj(user);
      } else {
        setIsLoggedIn(false);
      }
            
    });
  },[]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes forceRefresh>            
        <Route path="/" element={<Home />} ></Route>              
        <Route path="/studio" element={<Studio />} ></Route>      
        <Route path="/studioroom" element={<StudioRoom />} ></Route>      
        <Route path="/equipment" element={<Equip />} ></Route>      
        <Route path="/schedule" element={<Schedule />} ></Route>   
        <Route path="/address" element={<Address />} ></Route>     
        <Route path="/reservation" element={<Reservation userObj={userObj} />}  ></Route> 
        <Route path="/search" element={<Search />}  ></Route> 
        <Route path="/gallery" element={<GalleryPart />}  ></Route> 
        <Route path="/payment" element={<CheckoutPage />}  ></Route> 
        <Route path="/payment/success" element={<SuccessPage />}  ></Route> 
        <Route path="/payment/fail" element={<FailPage />}  ></Route> 

        { userObj ? (
            <Route path="/reservation/:reservationId" element={<ReservationView userObj={userObj} />}  ></Route> 
          ) : (
            <Route path="/reservation/:reservationId" element={<Auth />} ></Route>             
          )
        }        
        
        { isLoggedIn ? (
          <Route path="/reservation/write" element={<WriteReservation userObj={userObj} />} ></Route>             
        ) : (
          <Route path="/reservation/write" element={<Auth />} ></Route>             
        )
        }

      </Routes>      
      <Footer />
    </BrowserRouter>
  );
}

export default App;
